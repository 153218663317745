const pages = {
  company: [
    {
      title: 'Noticias',
      href: '/news',
      isNew: true,
    },
    {
      title: 'Nuestros clientes',
      href: '/clients',
      isNew: false,
    },
    {
      title: 'Contacto',
      href: '/about',
      isNew: false,
    },
  ],
  portfolio: [
    {
      title: 'Funerarias',
      href: '/funerarias',
      isNew: false,
    },
    {
      title: 'Ambulancias',
      href: '/ambulancias',
      isNew: false,
    },
    {
      title: 'Facturación',
      href: '/facturacion',
      isNew: true,
    }
  ],
};

export default pages;
