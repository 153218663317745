import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import NavItem from './components/NavItem';
import {getStrapiMedia} from "../../../../../../utils/media";
import LocaleSwitch from "../../../../../../components/locale-switch"
import { ThemeModeToggler } from "../../../index"

const SidebarNav = ({ pages, navbar, pageContext, themeToggler }) => {
  const theme = useTheme();
  const { mode } = theme.palette;

  const {
    company: companyPages,
    portfolio: portfolioPages,
  } = pages;

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={'flex'}
          component="a"
          href="/"
          title="Aerodrop"
          width={{ xs: 100, md: 120 }}
        >
          <Box
            component={'img'}
            src={
              mode === 'light'
                  ? getStrapiMedia(navbar.logo.bright.url)
                  : getStrapiMedia(navbar.logo.dark.url)
            }
            height={1}
            width={1}
          />
          {/*
          <Box marginLeft={1}>
            <LocaleSwitch pageContext={pageContext}/>
          </Box>
          */}
          <Box marginLeft={1}>
            <ThemeModeToggler themeToggler={themeToggler}/>
          </Box>
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
        <Box>
          <NavItem title={'Compañía'} items={companyPages} />
        </Box>
        <Box>
          <NavItem title={'Soluciones'} items={portfolioPages} />
        </Box>
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
};

export default SidebarNav;
