import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {alpha, useTheme} from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';

import {NavItem} from './components';
import LocaleSwitch from "../../../../components/locale-switch";
import {ThemeModeToggler} from "../index";
import {getStrapiMedia} from "../../../../utils/media";

const Topbar = ({onSidebarOpen, pages, pageContext, themeToggler, navbar, colorInvert = false}) => {
    const theme = useTheme();
    const {mode} = theme.palette;
    const {
        company: companyPages,
        portfolio: portfolioPages,
    } = pages;

    return (
        <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            width={1}
        >
            <Box
                display={'flex'}
                component="a"
                href="/"
                title="theFront"
                width={{xs: 100, md: 120}}
            >
                <Box
                    component={'img'}
                    src={
                        mode === 'light' && !colorInvert
                            ? getStrapiMedia(navbar.logo.bright.url)
                            : getStrapiMedia(navbar.logo.dark.url)
                    }
                    height={1}
                    width={1}
                />
            </Box>
            <Box sx={{display: {xs: 'none', md: 'flex'}}} alignItems={'center'}>
                <Box marginLeft={4}>
                    <NavItem
                        title={'Compañía'}
                        id={'company-pages'}
                        items={companyPages}
                        colorInvert={colorInvert}
                    />
                </Box>
                <Box marginLeft={4}>
                    <NavItem
                        title={'Soluciones'}
                        id={'portfolio-pages'}
                        items={portfolioPages}
                        colorInvert={colorInvert}
                    />
                </Box>
                <Box marginLeft={1}>
                    <ThemeModeToggler themeToggler={themeToggler}/>
                </Box>
            </Box>
            <Box sx={{display: {xs: 'block', md: 'none'}}} alignItems={'center'}>
                <Button
                    onClick={() => onSidebarOpen()}
                    aria-label="Menu"
                    variant={'outlined'}
                    sx={{
                        borderRadius: 2,
                        minWidth: 'auto',
                        padding: 1,
                        borderColor: alpha(theme.palette.divider, 0.2),
                    }}
                >
                    <MenuIcon/>
                </Button>
            </Box>
        </Box>
    );
};

Topbar.propTypes = {
    onSidebarOpen: PropTypes.func,
    pages: PropTypes.object,
    colorInvert: PropTypes.bool,
};

export default Topbar;
